import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Content from "common/page/Content";
import Header from "common/page/Header";
import Page from "common/page/Page";
import { getAnalytics } from "./AnalyticsActions";
import { getAllProductsWithVersions, getProducts } from "components/products/ProductActions";
import { getChartData } from "utils/AnalyticsUtils";
import AnalyticsChart from "./charts/Chart";
import Loader from "common/basicComponents/Loader";
import { checkRequiredSettings } from "utils/AnalyticsUtils";
import TabNavigation from "common/basicComponents/Tabs";
import { TabVariant } from "common/basicComponents/Tabs";
import Select from "common/basicComponents/Select";

const Tabs = {
  ANALYTICS: "analytics",
  CAMPAIGNS: "campaigns",
};

const Charts = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;
const Analytics = () => {
  const { t } = useTranslation("analyticsDashboard");
  const { partner } = useSelector((state) => state.auth);
  const { allProducts } = useSelector((state) => state.products);
  const { analytics, date } = useSelector((state) => state.analytics);
  const [loading, setLoading] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(Tabs.ANALYTICS);
  const [top5Active, setTop5Active] = useState(true);


  useEffect(() => {
    if (partner) {
      setLoading(true);
      if(partner.partner_name === 'Kodano PL' || partner.partner_name === 'Kodano CZ' || partner.partner_name === 'Kodano HU' || partner.partner_name === 'Kodano LT' || partner.partner_name === 'Kodano RO' || partner.partner_name === 'Kodano SK') {
        setTop5Active(false);
        Promise.all([getAnalytics()]).then(() =>
          setLoading(false)
        );
      } else {
      setTop5Active(true);
      Promise.all([getAnalytics(), getAllProductsWithVersions()]).then(() =>
        setLoading(false)
      );
      }}
  }, [partner]);

  const chartData = useMemo(
    () => getChartData(analytics, allProducts, top5Active),
    [analytics, allProducts]
  );

  const now = new Date();
  const today = now.toISOString().substring(0, 10);
  const yesterday = new Date(now.getDate() - 1).toISOString().substring(0, 10);

  const onesignalPlayers =
    partner.onesignal_players &&
    (partner.onesignal_players[today] || partner.onesignal_players[yesterday])
      ? Math.round(
          Number(
            partner.onesignal_players[today] ||
              partner.onesignal_players[yesterday]
          ) * 1.5
        )
      : null;

  const tabs = [
    {
      id: Tabs.ANALYTICS,
      title: t("title"),
      longTitle: t("title"),
      show: true,
      content: (
        <Charts key={`${Tabs.ANALYTICS}_tab`}>
          <Loader show={loading} height={20} />
          {chartData.data?.length > 0 &&
            !loading &&
            chartData.data.map((chart) =>
              checkRequiredSettings(partner, chart.requiredSettings || []) ? (
                <AnalyticsChart
                  key={chart.name}
                  labels={chartData.labels}
                  title={chart.name}
                  data={chart.data}
                  type={chart.type}
                />
              ) : null
            )}
        </Charts>
      ),
    },
    {
      id: Tabs.CAMPAIGNS,
      title: t("campaigns.title"),
      longTitle: t("campaigns.title"),
      show: true,
      content: (
        <div key={`${Tabs.CAMPAIGNS}_tab`}>
          <Select
            id="campaignsSelect"
            name="campaignsSelect"
            label={t("campaigns.campaign")}
            options={
              chartData.campaigns
                ? Object.keys(chartData.campaigns).map((key) => ({
                    value: key,
                    label: `${t("campaigns.campaign")} - ${key}`,
                  }))
                : []
            }
            placeholder={t("campaigns.select")}
            onChange={(option) => {
              setCampaignId(option.value);
            }}
          />
          <Charts>
            <Loader show={loading} height={20} />
            {campaignId && chartData.campaigns && !loading && (
              <>
                <AnalyticsChart
                  key={chartData.campaigns[campaignId].clicks.name}
                  labels={chartData.labels}
                  title={chartData.campaigns[campaignId].clicks.name}
                  data={chartData.campaigns[campaignId].clicks.data}
                  type={chartData.campaigns[campaignId].clicks.type}
                />
                <AnalyticsChart
                  key={chartData.campaigns[campaignId].installed.name}
                  labels={chartData.labels}
                  title={chartData.campaigns[campaignId].installed.name}
                  data={chartData.campaigns[campaignId].installed.data}
                  type={chartData.campaigns[campaignId].installed.type}
                />
              </>
            )}
          </Charts>
        </div>
      ),
    },
  ];
  return (
    <Page>
      <Header
        title={
          selectedTab === Tabs.ANALYTICS ? t("title") : t("campaigns.title")
        }
        subtitles={[
          [t("timeline") + ":", t("last30Days")],
          selectedTab === Tabs.ANALYTICS
            ? partner.mobile_active
              ? [t("downloads") + ":", onesignalPlayers || "-"]
              : []
            : [
                t("campaigns.number") + ":",
                Object.keys(chartData.campaigns).length,
              ],
          [t("lastUpdate") + ":", date],
        ]}
      />
      <Content>
        {chartData.campaigns && Object.keys(chartData.campaigns).length > 0 ? (
          <TabNavigation
            tabs={tabs.map((tab) => tab.title)}
            tabsLongTitles={tabs.map((tab) => tab.longTitle)}
            contents={tabs.map((tab) => tab.content)}
            variant={TabVariant.WIDE}
            onSelect={(index) => {
              setCampaignId(null);
              setSelectedTab(tabs[index].id);
            }}
          />
        ) : (
          <Charts>
            <Loader show={loading} height={20} />
            {chartData.data?.length > 0 &&
              !loading &&
              chartData.data.map((chart) =>
                checkRequiredSettings(partner, chart.requiredSettings || []) ? (
                  <AnalyticsChart
                    key={chart.name}
                    labels={chartData.labels}
                    title={chart.name}
                    data={chart.data}
                    type={chart.type}
                  />
                ) : null
              )}
          </Charts>
        )}
      </Content>
    </Page>
  );
};

export default Analytics;
